import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Cushion, Shadow, Space, Typography } from '@pitchero/react-ui';
import Image from 'components/basic-image';
import { openLoginPanel } from 'store/auth/actions';
import { isLoggedIn } from 'store/auth/selectors';
import Head from 'next/head';
import { Trans } from 'lib/i18n';
import LoginPanel from 'components/auth/login-panel';
import PitcheroLogo from 'components/pitchero-logo';
import PropTypes from 'prop-types';

import { pitcheroDomain } from 'lib/env';

const communityDomain = `https://${pitcheroDomain()}/community`;

const ClubNotLive = ({ club, loggedIn, openLogin }) => {
  const [initialLoggedInState] = useState(loggedIn);
  useEffect(() => {
    if (!initialLoggedInState && loggedIn) {
      window.location.reload();
    }
  }, [loggedIn]);
  return (
    <>
      <Head>
        <title>Club not live</title>
      </Head>
      <Cushion
        style={{ textAlign: 'center' }}
        top="xlarge"
        bottom="xxlarge"
        responsive={[{ minWidth: 'palm', props: { top: 'xxxlarge' } }]}
      >
        <Image
          src={club.logo}
          alt={club.name}
          height={90}
          width={90}
          style={{ maxWidth: 90 }}
          transformation="square"
        />
        <Cushion bottom="medium" top="medium" horizontal="medium">
          <Typography component="div" preset="staticSubtitle--large">
            <Trans i18nKey="common:club_not_live_main_message" values={{ clubName: club.name }}>
              This club isn&apos;t ready yet
            </Trans>
          </Typography>
        </Cushion>
        <Cushion horizontal="medium">
          <Typography component="div" preset="body--large" color="dustygrey">
            <Trans i18nKey="common:club_not_live_sub_message">
              In the meantime, why not check out other top clubs on pitchero?
            </Trans>
          </Typography>
        </Cushion>

        <Cushion component="div" vertical="large">
          <a href={`${communityDomain}/${club.sport}`} style={{ cursor: 'pointer' }}>
            <Shadow shadow="dark">
              <Button theme="primary">
                <Trans i18nKey={`common:not_live_cta_${club.sport}`}>
                  {`View ${club.sport} clubs`}
                </Trans>
              </Button>
            </Shadow>
          </a>
        </Cushion>

        {!initialLoggedInState && (
          <Cushion bottom="xlarge" component="div">
            <Typography preset="body--large" color="dustygrey">
              <Trans i18nKey="common:club_not_live_webmaster">
                Are you a webmaster for this club?
              </Trans>
            </Typography>

            <Cushion left="small">
              <Typography
                preset="body--large"
                color="primary"
                onClick={openLogin}
                style={{ cursor: 'pointer' }}
              >
                <Trans i18nKey="common:not_live_login_cta" values={{ sport: club.sport }}>
                  Login to manage the site
                </Trans>
              </Typography>
            </Cushion>
          </Cushion>
        )}
        <Space bottom="xsmall">
          <Cushion bottom="xsmall">
            <Typography color="black" preset="tag--large" component="div">
              <Trans i18nKey="common:powered_by">Powered by</Trans>
            </Typography>
          </Cushion>
          <PitcheroLogo />
        </Space>
      </Cushion>
      <LoginPanel />
    </>
  );
};

ClubNotLive.propTypes = {
  club: PropTypes.shape({
    sport: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  loggedIn: PropTypes.bool.isRequired,
  openLogin: PropTypes.func.isRequired,
};

const dispatchToProps = {
  openLogin: openLoginPanel,
};

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps, dispatchToProps)(ClubNotLive);
